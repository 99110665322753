/**
 * Global body
 */
body {
	position: relative;
	padding-top: $header_height_mobile;
	@include media-breakpoint-up(lg) {
		padding-top: $header_height_desktop;
	}
}

/**
 * Global Main
 */

#main {
	position: relative;
	z-index: 1;
	overflow-x: hidden;
	img {
		max-width: 100%;
	}
}

/**
 * A helper to scale an img element to the parent container
 * to act as a background image
 */

.g--bg-img {
	position: relative;
	& > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100% !important;
		object-fit: cover;
		object-position: center;
		& ~ .container {
			position: relative;
			z-index: 1;
		}
	}
}

/**
 * helper spacer classes
 */

.mr-25 {
	margin-right: 25px;
}
.mt-24 {
	margin-top: 24px;
}
.mb-24 {
	margin-bottom: 24px;
}
.mb-36 {
	margin-bottom: 36px;
}
.mb-48 {
	margin-bottom: 48px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-72 {
	margin-bottom: 72px;
}
.mb-84 {
	margin-bottom: 84px;
}
.mb-96 {
	margin-bottom: 96px;
}
.mb-108 {
	margin-bottom: 108px;
}
.mb-120 {
	margin-bottom: 120px;
}
.mb-0 {
	margin-bottom: 0;
}

@include media-breakpoint-up(md) {
	.mb-md-0 {
		margin-bottom: 0;
	}
}

@include media-breakpoint-up(lg) {
	.mb-lg-0 {
		margin-bottom: 0;
	}
}

.columns-2-md {
	@include media-breakpoint-up(md) {
		columns: 2;
		column-gap:100px;
	}
}

/**
 * Text align helpers
 */
.tal {
	text-align: left;
}
.tar {
	text-align: right;
}
.tac {
	text-align: center;
}
@include media-breakpoint-up(md) {
	.tac-md {
		text-align: center;
	}
	.tal-md {
		text-align: left;
	}
	.tar-md {
		text-align: right;
	}
}

@include media-breakpoint-up(lg) {
	.tar-lg {
		text-align: right;
	}
	.tal-lg {
		text-align: left;
	}
	.tac-lg {
		text-align: center;
	}
}

/**
 * RTL helper
 */
.rtl {
	direction: rtl;
}

/**
 * Heading Accent
 */
.g--heading-accent_r,
.g--heading-accent {
	& > span {
		position: relative;
		display: inline-block;
		&:before {
			content: "";
			position: absolute;
			top: -26px;
			left: -32px;
			width: 35px;
			height: 39px;
			background: {
				image: url(../images/heading-accent.svg);
				size: contain;
				position: bottom center;
				repeat: no-repeat;
			}
		}
	}
}

.g--heading-accent_r {
	& > span {
		&:before {
			left: unset;
			right: -20px;
			top: -25px;
			transform: scaleX(-1);
		}
	}
}

/**
 * Text Accent
 */

.g--text-accent_secondary {
	display: inline-block;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		bottom: -8px;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: {
			image: url(../images/text-accent_yellow.svg);
			size: contain;
			position: bottom center;
			repeat: no-repeat;
		}
	}
}

/**
 * Pretitle Styling
 */
.g--pretitle {
	span {
		color: $yellow;
		background-color: $light-yellow;
		border-radius: 50px;
		padding: 7px 22px;
		margin-bottom: 10px;
		font-size: 15.5px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 1.86px;
		text-transform: uppercase;
		display: inline-block;
	}
}

/**
 * Padding helpers
 */
.pl-30 {
	padding-left: 30px;
}
@include media-breakpoint-up(md) {
	.pl-md-0 {
		padding-left: 0;
	}
}