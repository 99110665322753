#site-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	height: $header_height_mobile;
	background-color: transparent;
	transition: all 0.3s ease-in-out;
	display: flex;
	align-items: center;
	width: 100%;

	& > .container {
		height: 100%;
		& > .row {
			height: 100%;
			& > .col-6 {
				height: 100%;
			}
		}
	}

	.header-blue & {
		background-color: $light-blue;
	}

	.menu-open &,
	.shrink & {
		background-color: white;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	}

	.header--info {
		display: flex;
		height: 100%;
	}

	.header--info_logo {
		display: flex;
		align-items: center;
		a {
			display: block;
		}
		img {
			width: 90px;
		}
	}

	.header--contact {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.btn {
			display: none;
			max-width: none;
			width: auto;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.header--info {
			gap: 20px;
		}

		.header--info_logo {
			img {
				width: 122px;
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		height: $header_height_desktop;

		.shrink & {
			height: calc(#{$header_height_desktop} - 45px);
		}
		.header--info {
			gap: 77px;
		}

		.header--contact .btn {
			display: inline-block;
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
