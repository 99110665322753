#site-footer {
    overflow: hidden;
    position: relative;
    z-index: 1;
    * {
        color: white;
    }
    .site-footer--inner {
        position: relative;
        border-radius: 17px;
        background-color: $dark-blue;
        width: 100%;
        margin: 0 auto 46px;
        padding: 75px 0 45px;
    }
    .site-footer--accent_lemon {
        position: absolute;
        width: 129px;
        height: 129px;
        z-index: -1;
        left: -75px;
        top: 100px;
    }

    .site-footer--accent_target {
        position: absolute;
        width: 265px;
        height: 265px;
        bottom: -150px;
        right: -140px;
        z-index: -1;
    }

    .site-footer--description {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 30px;
        }
    }

    .site-footer--menu_title {
        margin-bottom: 39px;
    }

    .site-footer--menu {
        list-style-type: none;
        margin: 0;
        padding-left: 20px;
        li {
            margin-bottom: 10px;
            position: relative;
            &:before {
                @include iconFont;
                content: "\";
                position: absolute;
                left: -20px;
                top: 8px;
                font-size: 10px;
            }
            a {
                text-decoration: none;
                transition: color 0.3s ease-in-out;
                &:hover {
                    color: $secondary;
                }
            }
        }
    }

    .site-footer--contact_menu {
        list-style-type: none;
        margin: 0;
        padding-left: 30px;
        li {
            margin-bottom: 10px;
            position: relative;
            &:before {
                @include iconFont;
                position: absolute;
                left: -30px;
                top: 5px;
            }
            &.site-footer--contact_menu__phone {
                &:before {
                    content: "\";
                }
            }
            &.site-footer--contact_menu__email {
                &:before {
                    content: "\";
                }
            }
            a {
                text-decoration: none;
                transition: color 0.3s ease-in-out;
                &:hover {
                    color: $secondary;
                }
            }
        }
    }

    .site-footer--social_menu {
        list-style-type: none;
        margin: 30px 0 0;
        padding: 0;
        display: flex;
        gap: 7px;
        li {
            a {
                text-decoration: none;
                display: block;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                &:hover {
                    background-color: $secondary;
                    border-color: $secondary;
                }
                i {
                    line-height: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .site-footer--socket {
        margin-top: 61px;
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        a {
            transition: color 0.3s ease-in-out;
            text-decoration: none;
            &:hover {
                color: $secondary;
            }
        }
    }

    /* =============================================================================
    Extra Small +
    ========================================================================== */
    @include media-breakpoint-up(xs) {
    }

    /* =============================================================================
    Small +
    ========================================================================== */
    @include media-breakpoint-up(sm) {
    }

    /* =============================================================================
    Medium +
    ========================================================================== */
    @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
        .site-footer--inner {
            & > .container {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }

    /* =============================================================================
    Large +
    ========================================================================== */
    @include media-breakpoint-up(lg) {
    }

    /* =============================================================================
    Extra Large +
    ========================================================================== */
    @include media-breakpoint-up(xl) {
    }
}
