
@font-face {
  font-family: "iconFont";
  src:  url('../fonts/iconFont.woff2?1698131857') format('woff2'),
        url('../fonts/iconFont.woff?1698131857') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@mixin iconFont {

  font-family: 'iconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  background-color:transparent;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}





[class^="customicon-"], [class*="customicon-"] {
  
  &:before {@include iconFont;}

}



.customicon-twitter:before {
  content: "\"
}
.customicon-linkedin:before {
  content: "\"
}
.customicon-facebook:before {
  content: "\"
}
.customicon-instagram:before {
  content: "\"
}
.customicon-youtube:before {
  content: "\"
}
.customicon-arrow-right:before {
  content: "\"
}
.customicon-chevron-right:before {
  content: "\"
}
.customicon-chevron-left:before {
  content: "\"
}
.customicon-pen-ruler:before {
  content: "\"
}
.customicon-paw:before {
  content: "\"
}
.customicon-graduation-cap:before {
  content: "\"
}
.customicon-scroll:before {
  content: "\"
}
.customicon-checkmark:before {
  content: "\"
}
.customicon-quote:before {
  content: "\"
}
.customicon-phone:before {
  content: "\"
}
.customicon-envelope:before {
  content: "\"
}
.customicon-location-dot:before {
  content: "\"
}
.customicon-chevron-down:before {
  content: "\"
}
.customicon-person-chalkboard:before {
  content: "\"
}
.customicon-book-open-reader:before {
  content: "\"
}
.customicon-award:before {
  content: "\"
}
.customicon-user-graduate:before {
  content: "\"
}
.customicon-arrow-right-long:before {
  content: "\"
}
.customicon-calendar-days:before {
  content: "\"
}