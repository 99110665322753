.desktop-menu {
	display: none;
	nav {
		height: 100%;
		& > ul {
			height: 100%;
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			gap: 40px;
			& > li {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				& > a {
					font-weight: 600;
					text-decoration: none;
					color: $dark-blue;
					letter-spacing: 0.16px;
					transition: color 0.3s ease;
					&:hover {
						color: $secondary;
					}
				}
				&.has-children {
					position: relative;
					& > a {
						padding-right: 18px;
						position: relative;
						&:after {
							@include iconFont;
							content: "\";
							position: absolute;
							right: 0;
							top: 8px;
							font-size: 10px;
						}
					}
					&:hover {
						.mega-menu,
						.sub-menu {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}

	.sub-menu {
		opacity: 0;
		visibility: hidden;
		background-color: white;
		position: absolute;
		top: 100%;
		left: 0;
		list-style-type: none;
		padding: 0;
		margin: 0;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
		padding: 20px 30px;
		border-radius: 10px;
		transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s;
		& > li {
			& + li {
				margin-top: 5px;
			}
			& > a {
				white-space: nowrap;
				font-weight: 400;
				text-decoration: none;
				color: $dark-blue;
				letter-spacing: 0.16px;
				transition: color 0.3s ease;
				&:hover {
					color: $secondary;
				}
			}
		}
	}

	.mega-menu {
		border-top: 1px solid #e5e5e5;
		background-color: white;
		position: fixed;
		top: $header_height_desktop;
		width: 100vw;
		left: 0;
		transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s,
			top 0.3s ease-in-out;
		opacity: 0;
		visibility: hidden;
		box-shadow: 0px 60px 30px rgba(0, 0, 0, 0.35);
		padding-top: 55px;
		padding-bottom: 75px;
		.shrink & {
			top: calc(#{$header_height_desktop} - 45px);
		}

		.mega-sub-menu {
			padding-left: 0;
			list-style-type: none;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 1px;
				height: 100%;
				background-color: #e5e5e5;
			}
		}

		.mega-menu--item {
			display: block;
			text-decoration: none;
			color: $grey-dark;
			font-size: 15px;
			line-height: 25px;
			position: relative;
			&:before {
				content: "";
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: calc(100% + 40px);
				height: calc(100% + 40px);
				background-color: lighten($grey-light, 5%);
				border-radius: 15px;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
			}
			p {
				position: relative;
			}
		}
		.mega-menu--item_title {
			display: flex;
			align-items: center;
			gap: 16px;
			color: $dark-blue;
			font-size: 27px;
			font-family: $quicksand;
			margin-bottom: 10px;
			position: relative;
			&.primary {
				i {
					color: $primary;
					background-color: $light-blue;
				}
			}
			&.secondary {
				i {
					color: $secondary;
					background-color: $light-yellow;
				}
			}
			&.tertiary {
				i {
					color: $tertiary;
					background-color: $light-green;
				}
			}
			&.quaternary {
				i {
					color: $quaternary;
					background-color: $light-orange;
				}
			}
			i {
				width: 61px;
				height: 61px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: block;
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
