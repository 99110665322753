/**
 * Typography overrides. Use this for more control over the theme's
 * default font settings.
 */
body {
	font-family: $pFont;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.7em;
	color:$pColor;
	letter-spacing: 0.16px;
	#main p a {
		text-decoration: none;
		color: $primary;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color:$hColor;
		font-family: $hFont;
		margin: 0 0 0.25em 0;
		font-weight: 700;
		line-height: 1.1em;
		letter-spacing: normal;
	}


	.wysiwyg {
		h1,h2,h3,h4,h5,h6 {
			margin-bottom: 0.5em;
			&:not(:first-child) {
				margin-top: 1.5em;
			}
		}
		@include media-breakpoint-up(lg) {
			h1,
			.h1 {
				font-size: 60px;
			}
			h2,
			.h2 {
				font-size: 36px;
			}
			h3,
			.h3 {
				font-size: 34px;
			}

			h4,
			.h4 {
				font-size: 30px;
			}

			h5,
			.h5 {
				font-size: 25px;
			}

			h6,
			.h6 {
				font-size: 20px;
			}
		}
	}


	h1,
	.h1 {
		font-size: 42px;
	}
	h2,
	.h2 {
		font-size: 36px;
	}
	h3,
	.h3 {
		font-size: 28px;
	}

	h4,
	.h4 {
		font-size: 24px;
	}

	h5,
	.h5 {
		font-size: 20px;
	}

	h6,
	.h6 {
		font-size: 17px;
	}

	@include media-breakpoint-up(md) {
		h1,
		.h1 {
			font-size: 50px;
		}
	}

	@include media-breakpoint-up(lg) {
		h1,
		.h1 {
			font-size: 60px;
		}
		h2,
		.h2 {
			font-size: 50px;
		}
		h3,
		.h3 {
			font-size: 44px;
		}

		h4,
		.h4 {
			font-size: 35px;
		}

		h5,
		.h5 {
			font-size: 30px;
		}

		h6,
		.h6 {
			font-size: 20px;
		}
	}

	p {
		&:first-of-type {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.fw-300 {
		font-weight: 300;
	}
	.fw-400 {
		font-weight: 400;
	}
	.fw-600 {
		font-weight: 600;
	}
	.fw-700 {
		font-weight: 700;
	}
	.fw-800 {
		font-weight: 800;
	}

}

.text-primary {
	color: $primary;
}
.text-secondary {
	color: $secondary;
}
.text-tertiary {
	color: $tertiary;
}
.text-quaternary {
	color: $quaternary;
}



/**
 * A customized marker list
 */

.g--checkmark-list {
	list-style-type:none;
	padding-left: 0;
	margin:30px 0;
	&.theme--secondary {
		li {
			&:before {
				color: $secondary;
				background-color:$light-yellow;
			}
		}
	}
	&.theme--tertiary {
		li {
			&:before {
				color: $tertiary;
				background-color:$light-green;
			}
		}
	}
	&.theme--quaternary {
		li {
			&:before {
				color: $quaternary;
				background-color:$light-orange;
			}
		}
	}
	li {
		position: relative;
		padding-left: 40px;
		margin-bottom: 16px;
		&:before {
			content: "\";
			@include iconFont;
			position: absolute;
			left:0;
			top:0;
			color:$primary;
			background-color: $light-blue;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			display: flex;
			align-items:center;
			justify-content:center;
			font-size: 10px;
		}
	}
}