.container {
		width: 100%;
		padding-right: 12px;
		padding-left: 12px;
		margin-right: auto;
		margin-left: auto;


		@include media-breakpoint-up(sm) {
			max-width: $container_sm;
		}
		@include media-breakpoint-up(md) {
			max-width: $container_md;
		}
		@include media-breakpoint-up(lg) {
			max-width: $container_lg;
		}
		@include media-breakpoint-up(xl) {
			max-width: $container_xl;
		}
}

.row {
	display: flex;
	flex-wrap:wrap;
	margin-left: -12px;
	margin-right: -12px;
	&.align-items-center {
		align-items:center;
	}
	&.justify-content-center {
		justify-content:center;
	}
	&.justify-content-space-between {
		justify-content:space-between;
	}
	&.flex-md-row-reverse {
		@include media-breakpoint-up(md) {
			flex-direction:row-reverse;
		}
	}
	&.gutter-96 {
		margin-left: -48px;
		margin-right: -48px;
		> [class^="col-"] {
			padding-left: 48px;
			padding-right: 48px;
		}
	}
	&.gutter-84 {
		margin-left: -42px;
		margin-right: -42px;
		> [class^="col-"] {
			padding-left: 42px;
			padding-right: 42px;
		}
	}
	&.gutter-72 {
		margin-left: -36px;
		margin-right: -36px;
		> [class^="col-"] {
			padding-left: 36px;
			padding-right: 36px;
		}
	}
	&.gutter-60 {
		margin-left: -30px;
		margin-right: -30px;
		> [class^="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	&.gutter-48 {
		margin-left: -24px;
		margin-right: -24px;
		> [class^="col-"] {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
	&.gutter-36 {
		margin-left: -18px;
		margin-right: -18px;
		> [class^="col-"] {
			padding-left: 18px;
			padding-right: 18px;
		}
	}

	&.gutter-12 {
		margin-left: -12px;
		margin-right: -12px;
		> [class^="col-"] {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	&.gutter-0 {
		margin-left: -0px;
		margin-right: -0px;
		> [class^="col-"] {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

}

@include media-breakpoint-up(md) {
	&.gutter-md-96 {
		margin-left: -48px;
		margin-right: -48px;
		> [class^="col-"] {
			padding-left: 48px;
			padding-right: 48px;
		}
	}
	&.gutter-md-84 {
		margin-left: -42px;
		margin-right: -42px;
		> [class^="col-"] {
			padding-left: 42px;
			padding-right: 42px;
		}
	}
	&.gutter-md-72 {
		margin-left: -36px;
		margin-right: -36px;
		> [class^="col-"] {
			padding-left: 36px;
			padding-right: 36px;
		}
	}
	&.gutter-md-60 {
		margin-left: -30px;
		margin-right: -30px;
		> [class^="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	&.gutter-md-48 {
		margin-left: -24px;
		margin-right: -24px;
		> [class^="col-"] {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
	&.gutter-md-36 {
		margin-left: -18px;
		margin-right: -18px;
		> [class^="col-"] {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
	&.gutter-md-12 {
		margin-left: -12px;
		margin-right: -12px;
		> [class^="col-"] {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	&.gutter-md-0 {
		margin-left: -0px;
		margin-right: -0px;
		> [class^="col-"] {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@include media-breakpoint-up(lg) {
	&.gutter-lg-96 {
		margin-left: -48px;
		margin-right: -48px;
		> [class^="col-"] {
			padding-left: 48px;
			padding-right: 48px;
		}
	}
	&.gutter-lg-84 {
		margin-left: -42px;
		margin-right: -42px;
		> [class^="col-"] {
			padding-left: 42px;
			padding-right: 42px;
		}
	}
	&.gutter-lg-72 {
		margin-left: -36px;
		margin-right: -36px;
		> [class^="col-"] {
			padding-left: 36px;
			padding-right: 36px;
		}
	}
	&.gutter-lg-60 {
		margin-left: -30px;
		margin-right: -30px;
		> [class^="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	&.gutter-lg-48 {
		margin-left: -24px;
		margin-right: -24px;
		> [class^="col-"] {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
	&.gutter-lg-36 {
		margin-left: -18px;
		margin-right: -18px;
		> [class^="col-"] {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
	&.gutter-lg-12 {
		margin-left: -12px;
		margin-right: -12px;
		> [class^="col-"] {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	&.gutter-lg-0 {
		margin-left: -0px;
		margin-right: -0px;
		> [class^="col-"] {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@include media-breakpoint-up(xl) {
	&.gutter-xl-96 {
		margin-left: -48px;
		margin-right: -48px;
		> [class^="col-"] {
			padding-left: 48px;
			padding-right: 48px;
		}
	}
	&.gutter-xl-84 {
		margin-left: -42px;
		margin-right: -42px;
		> [class^="col-"] {
			padding-left: 42px;
			padding-right: 42px;
		}
	}
	&.gutter-xl-72 {
		margin-left: -36px;
		margin-right: -36px;
		> [class^="col-"] {
			padding-left: 36px;
			padding-right: 36px;
		}
	}
	&.gutter-xl-60 {
		margin-left: -30px;
		margin-right: -30px;
		> [class^="col-"] {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	&.gutter-xl-48 {
		margin-left: -24px;
		margin-right: -24px;
		> [class^="col-"] {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
	&.gutter-xl-36 {
		margin-left: -18px;
		margin-right: -18px;
		> [class^="col-"] {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
	&.gutter-xl-12 {
		margin-left: -12px;
		margin-right: -12px;
		> [class^="col-"] {
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	&.gutter-xl-0 {
		margin-left: -0px;
		margin-right: -0px;
		> [class^="col-"] {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

[class^="col-"] {
	padding-left: 12px;
	padding-right: 12px;
	width: 100%;
}

.align-items-center {
	align-items:center;
}
.justify-content-start {
	justify-content:flex-start;
}
.justify-content-center {
	justify-content:center;
}
.justify-content-end {
	justify-content:flex-end;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}

.col-2 {
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.col-5 {
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}

.col-8 {
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}

.col-11 {
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

@include media-breakpoint-up(sm) {

  .col-sm {
    col-basis: 0;
	col-grow: 1;
	max-width: 100%;
  }

  .col-sm-auto {
	  flex: 0 0 auto;
	width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
	  flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
  }

  .col-sm-2 {
	  flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
  }

  .col-sm-3 {
	  flex: 0 0 25%;
	max-width: 25%;
  }

  .col-sm-4 {
	  flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
  }

  .col-sm-5 {
	  flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
  }

  .col-sm-6 {
	  flex: 0 0 50%;
	max-width: 50%;
  }

  .col-sm-7 {
	  flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
  }

  .col-sm-8 {
	  flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
  }

  .col-sm-9 {
	  flex: 0 0 75%;
	max-width: 75%;
  }

  .col-sm-10 {
	  flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
  }

  .col-sm-11 {
	  flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
  }

  .col-sm-12 {
	  flex: 0 0 100%;
	max-width: 100%;
  }
}

@include media-breakpoint-up(md) {

  .col-md {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
  }
  .col-md-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
  }

  .col-md-1 {
	  flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
  }

  .col-md-2 {
	  flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
  }

  .col-md-3 {
	  flex: 0 0 25%;
	max-width: 25%;
  }

  .col-md-4 {
	  flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
  }

  .col-md-5 {
	  flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
  }

  .col-md-6 {
	  flex: 0 0 50%;
	max-width: 50%;
  }

  .col-md-7 {
	  flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
  }

  .col-md-8 {
	  flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
  }

  .col-md-9 {
	  flex: 0 0 75%;
	max-width: 75%;
  }

  .col-md-10 {
	  flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
  }

  .col-md-11 {
	  flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
  }

  .col-md-12 {
	  flex: 0 0 100%;
	max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {

 .col-lg {
    col-basis: 0;
	col-grow: 1;
	max-width: 100%;
 }

 .col-lg-auto {
	  flex: 0 0 auto;
	width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
	  flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
  }

  .col-lg-2 {
	  flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
  }

  .col-lg-3 {
	  flex: 0 0 25%;
	max-width: 25%;
  }

  .col-lg-4 {
	  flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
  }

  .col-lg-5 {
	  flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
  }

  .col-lg-6 {
	  flex: 0 0 50%;
	max-width: 50%;
  }

  .col-lg-7 {
	  flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
  }

  .col-lg-8 {
	  flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
  }

  .col-lg-9 {
	  flex: 0 0 75%;
	max-width: 75%;
  }

  .col-lg-10 {
	  flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
  }

  .col-lg-11 {
	  flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
  }

  .col-lg-12 {
	  flex: 0 0 100%;
	max-width: 100%;
  }
  .col-lg-5th {
  	flex: 0 0 20%;
  	max-width:20%;
  }
}

@include media-breakpoint-up(xl) {
  .col-xl {
	col-basis: 0;
	col-grow: 1;
	max-width: 100%;
  }
  .col-xl-auto {
	  flex: 0 0 auto;
	width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
	  flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
  }

  .col-xl-2 {
	  flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
  }

  .col-xl-3 {
	  flex: 0 0 25%;
	max-width: 25%;
  }

  .col-xl-4 {
	  flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
  }

  .col-xl-5 {
	  flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
  }

  .col-xl-6 {
	  flex: 0 0 50%;
	max-width: 50%;
  }

  .col-xl-7 {
	  flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
  }

  .col-xl-8 {
	  flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
  }

  .col-xl-9 {
	  flex: 0 0 75%;
	max-width: 75%;
  }

  .col-xl-10 {
	  flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
  }

  .col-xl-11 {
	  flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
  }

  .col-xl-12 {
	  flex: 0 0 100%;
	max-width: 100%;
  }
}