/**
 * Theme vars
 */

// colours
$blue: #009aba;
$light-blue: #e4f5fa;
$green: #5bbcb1;
$light-green: #e8f7ef;
$yellow: #eeb249;
$light-yellow: #fdf5e7;
$orange: #ee825c;
$light-orange: #f9ebe6;
$dark-blue: #014466;

// Greys
$grey-dark: #515151;
$grey-med: #b0b0b0;
$grey-light: #ebebeb;

$primary: $blue;
$secondary: $yellow;
$tertiary: $green;
$quaternary: $orange;

$pColor: $grey-dark;
$hColor: $dark-blue;

$warning: #ffeead;
$warning_text: darken($warning, 60%);

// fonts
$quicksand: "Quicksand", sans-serif;
$poppins: "Poppins", sans-serif;

$hFont: $quicksand;
$pFont: $poppins;

// other
$transition: all 0.3s ease;

// breakpoints
$breakpoint_xs: 0;
$breakpoint_sm: 600px;
$breakpoint_md: 768px;
$breakpoint_lg: 992px;
$breakpoint_xl: 1360px;

// grid container
$container_sm: 560px;
$container_md: 728px;
$container_lg: 960px;
$container_xl: 1320px;

// header height
$header_height_desktop: 125px;
$header_height_mobile: 80px;
